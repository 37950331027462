.order {
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
}

.order-title {
  margin-top: 5rem;
  margin-bottom: 2rem;

  font-size: xx-large;

  text-align: center;
  font-family: var(--font-family);
  color: var(--color-white);
}

.order-menu {
  width: 100%;
  margin: 2rem 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.order-menu-winterlimited,
.order-menu-tea {
  display: flex;
  justify-content: center;
  align-items: center;

  flex: 1;
  width: 100%;
  flex-direction: column;
}

.order-menu-heading {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 45px;
  line-height: 58.5px;
  letter-spacing: 0.04em;
  color: var(--color-white);
}

.order-menu-items {
  display: flex;
  flex-wrap: wrap;

  padding: 0 1rem;
  margin: 2rem 0;
  width: 100%;
}
