@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700;800;900&display=swap');

:root {
  --font-family: 'Raleway', sans-serif;

  --color-red: #a73535fa;
  --color-black: #000000;
  --color-gray: #545454;
  --color-crimson: #F5EFDB;
  --color-grey: #AAAAAA;
  --color-white: #FFFFFF;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  color: unset;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}