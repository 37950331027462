.home {
    background: var(--color-black);
    color: white;
    font-family: var(--font-family);

    display: flex;
    flex-direction: column;
}

.welcome {
    flex-wrap: wrap;
    padding-top: 5rem;
}

.welcome-info {
    height: 100vh;
    width: 100%;
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.welcome-info h1,
.welcome-info h2,
.welcome-info p,
.welcome-info a {
    padding: 0 2rem;
}

.welcome-info h1,
.maps-info h1 {
    font-weight: 700;
    letter-spacing: -0.04em;
    text-transform: capitalize;
    line-height: 117px;
    font-size: 90px;
}

.wrapper-img img {
    width: 80%;
    border-radius: 5px;
}

.button-arrow {
    margin: 1px 0 0 8px;
}

.maps {
    flex-wrap: wrap;
}

.wrapper-map iframe {
    width: 90%;
    height: 400px;

    padding: 2rem 0;
    border: none;
}

@media screen and (min-width: 2000px) {
    .welcome-info h1,
    .maps-info h1 {
        font-size: 180px;
        line-height: 210px;
    }
    .welcome-info h2
    .welcome-info p
    .maps-info h2 {
        font-size: 180px;
        line-height: 210px;
    }
    .wrapper-map iframe {
        height: 700px;
    }
}

@media screen and (max-width: 1150px) {
    .welcome-info h1,
    .maps-info h1 {
        font-size: 70px;
        line-height: 72px;
    }
    .maps-info h2 {
        font-size: 40px;
        line-height: 48px;
    }
    .welcome-info p,
    .maps-info p {
        font-size: 24px;
        line-height: 32px;
    }
}

@media screen and (max-width: 650px) {
    .welcome-info h1,
    .maps-info h1 {
        font-size: 50px;
        line-height: 54px;
    }
    .maps-info h2 {
        font-size: 30px;
        line-height: 32px;
    }
    .welcome-info p,
    .maps-info p {
        font-size: 20px;
        line-height: 24px;
    }

}

@media screen and (max-width: 450px) {
    .welcome-info h1,
    .maps-info h1 {
        letter-spacing: -.04em;
        font-size: 40px;
        line-height: 45px;
    }
    .maps-info h2 {
        font-size: 25px;
        line-height: 32px;
    }
    .welcome-info p,
    .maps-info p {
        font-size: 17px;
        line-height: 30px;
    }
}