.menuitem {
    width: 48%;
    height: 100px;
    margin: 1rem .5rem;
    border-radius: 10px;

    display: flex;
    flex-direction: row;

    background: #1d1d1d9c;
}

.menuitem-head {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.menuitem-name {
    color: var(--color-white);
    font-family: var(--font-family);
    font-size: 17px;
    font-weight: 600;
}

.menuitem-dash {
    width: 90px;
    height: 1px;
    background: var(--color-crimson);
    margin: .5rem 0;
}

.menuitem-price {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    font-family: var(--font-family);
    font-weight: 500;
    color: antiquewhite;
}

.menuitem-image {
    width: 100px;
    margin: 1rem 2rem;
}

.menuitem-image img {
    width: 100%;
    height: 70px;
    object-fit: cover;
}