.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-family: var(--font-family);

  background-attachment: fixed;

  padding: 1rem 2rem;
  width: 100%;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(167, 53, 53, 1) 100%
  );
}

.navbar-scroll {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(167, 53, 53, 0.75) 100%
  );
}

.navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.navbar-logo img {
  width: 180px;
}

.navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  height: 100%;
}

.navbar-links li {
  margin: 0 20px;
  position: relative;
}

.navbar-links a {
  text-decoration: none;
  display: block;
  padding: 5px 0;
  font-size: 26px;
  line-height: 1;

  position: relative;

  text-align: center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to right, #000000, #000000 50%, #fff 50%);
  background-size: 200% 100%;
  background-position: -100%;
  transition: all 0.3s ease-in-out;
}

.navbar-links a:before {
  display: block;
  content: "";
  width: 0;
  height: 3px;
  bottom: 5px;
  left: 0;
  bottom: -3px;
  position: absolute;
  background: #000;
  transition: all 0.3s ease-in-out;
}

.navbar-links a:hover {
  background-position: 0%;
}

.navbar-links a:hover::before {
  width: 100%;
}

.navbar-menu {
  display: none;
}

.navbar-menu-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.navbar-menu-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: #000000f1;
  transition: 0.5s ease;

  flex-direction: column;
  z-index: 5;
}

.overlay-close {
  font-size: 27px;
  color: var(--color-white);
  cursor: pointer;

  position: absolute;
}

.navbar-menu-links li {
  margin: 2rem;
  cursor: pointer;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
}

.navbar-menu-links li:hover {
  color: gray;
}

.login-button {
  padding: 15px 20px;
  margin: 10px 0;
  color: #fff;
  font-family: var(--font-family);
  font-size: 26px;
  text-align: center;
  position: relative;
  text-decoration: none;
  display: inline-block;
}

.login-button span {
  position: relative;
}

.login-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #000;
  -webkit-transform: scaleY(0.3);
  transform: scaleY(0.3);
  opacity: 0;
  transition: all 0.3s;
}

.login-button:hover {
  color: #000;
}

.login-button:hover::before {
  opacity: 1;
  background-color: #fff;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  transition: -webkit-transform 0.6s cubic-bezier(0.08, 0.35, 0.13, 1.02),
    opacity 0.4s;
  transition: transform 0.6s cubic-bezier(0.08, 0.35, 0.13, 1.02), opacity;
}

.sidebar {
  display: flex;
  position: fixed;
  flex-direction: column;
  top: 35%;
  left: 0px;
  z-index: 10;
}

.sidebar li {
  width: 160px;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: -100px;
  transition-duration: 300ms;

  background-color: var(--color-red);
}

.sidebar li:hover {
  margin-left: -10px;
  transition-duration: 300ms;
  border-radius: 5px;
}

.sidebar a {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  color: gainsboro;
}

@media screen and (min-width: 2000px) {
  .navbar-logo img {
    width: 210px;
  }
}

@media screen and (max-width: 1150px) {
  .navbar-links,
  .sidebar,
  .login-button {
    display: none;
  }

  .navbar-menu {
    display: flex;
  }

  .overlay-close {
    top: 40px;
    right: 32px;
  }
}

@media screen and (max-width: 650px) {
  .navbar {
    padding: 1rem;
  }

  .navbar-logo img {
    width: 110px;
  }

  .overlay-close {
    top: 26px;
    right: 16px;
  }
}
