.section-padding {
  padding: 0 6rem;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 100vh;
}

.wrapper-info {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.wrapper-img {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 2rem;
}

.wrapper-map {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  margin-right: 2rem;
}

.wrapper-img-reverse {
  justify-content: flex-start;
  margin-right: 2rem;
}

.wrapper-img img {
  width: 80%;
}

.custom-button {
  appearance: none;
  background: transparent;
  border: 0;
  color: #fff;
  cursor: pointer;
  font: inherit;
  font-weight: 500;
  line-height: 1;
  padding: 1em 1.5em;
  margin: 1rem;
  position: relative;
  transition: filter 0.3s;
}

.custom-button:hover {
  filter: brightness(1.1);
}

.custom-button:active {
  filter: brightness(0.9);
}

.custom-button > .custom-span {
  display: block;
  position: relative;
  transition: transform cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.3s;
  z-index: 1;
}

.custom-button:hover > .custom-span {
  transform: scale(1.05);
}

.custom-button:active > .custom-span {
  transform: scale(0.95);
}

.custom-button > .custom-svg {
  fill: #ff2828;
  position: absolute;
  top: -5%;
  left: -5%;
  width: 110%;
  height: 110%;
}

.custom-button > .custom-svg > .custom-path {
  transition: cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.3s;
}

.custom-button:hover > .custom-svg > .custom-path {
  d: path("M0,0 C0,-5 100,-5 100,0 C105,0 105,100 100,100 C100,105 0,105 0,100 C-5,100 -5,0 0,0");
}

.custom-button:active > .custom-svg > .custom-path {
  d: path("M0,0 C30,10 70,10 100,0 C95,30 95,70 100,100 C70,90 30,90 0,100 C5,70 5,30 0,0");
}

@media screen and (min-width: 2000px) {
  .custom-button,
  .p__cormorant {
    font-size: 37px;
    line-height: 67px;
  }

  .p__opensans {
    font-size: 30px;
    line-height: 50px;
  }

  .headtext__cormorant {
    font-size: 150px;
    line-height: 210px;
  }
}

@media screen and (max-width: 1150px) {
  .wrapper {
    padding-top: 5rem;
    flex-direction: column;
  }

  .wrapper-img {
    margin: 5rem 0 0 0;
  }

  .wrapper-img-reverse {
    margin: 0 0 5rem 0;
  }

  .wrapper-img img {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .section-padding {
    padding: 4rem;
  }
}

@media screen and (max-width: 650px) {
  .section-padding {
    padding: 2rem 1rem;
  }
}

@media screen and (max-width: 450px) {
  .p__opensans {
    font-size: 12px;
  }

  .p__cormorant {
    font-size: 19px;
  }

  .headtext__cormorant {
    font-size: 45px;
    line-height: 70px;
  }
}
