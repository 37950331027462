.resetpass {
    min-height: 100vh;
    font-family: var(--font-family);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(167, 53, 53, 1) 100%
    );
  }
  
  .resetpass-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background: rgb(255, 255, 255);
    border-radius: 10px;
  }
  
  .resetpass-center h1 {
    text-align: center;
    padding: 20px 0 20px 0;
    border-bottom: 1px solid silver;
  }
  
  .resetpass-center form {
    padding: 0 40px;
    box-sizing: border-box;
  }
  
  form .resetpass-input {
    position: relative;
    border-bottom: 2px solid #adadad;
    margin: 30px 0;
  }
  
  .resetpass-input input {
    width: 100%;
    padding: 0 5px;
    height: 40px;
    font-size: 16px;
    border: none;
    background: none;
    outline: none;
  }
  
  .resetpass-input label {
    position: absolute;
    top: 50%;
    left: 5px;
    color: #adadad;
    transform: translateY(-50%);
    font-size: 16px;
    pointer-events: none;
    transition: 0.5s;
  }
  
  .resetpass-input span::before {
    content: "";
    position: absolute;
    top: 40px;
    left: 0;
    width: 0%;
    height: 2px;
    background: var(--color-red);
    transition: 0.5s;
  }
  
  .resetpass-input input:focus ~ label,
  .resetpass-input input:valid ~ label {
    top: -5px;
    color: var(--color-red);
  }
  
  .resetpass-input input:focus ~ span::before,
  .resetpass-input input:valid ~ span::before {
    width: 100%;
  }
  
  .resetpass-pass {
    margin: -5px 0 20px 5px;
    color: #a6a6a6;
    cursor: pointer;
    width: fit-content;
  }
  
  .resetpass-pass:hover {
    text-decoration: underline;
  }
  
  input[type="submit"] {
    width: 100%;
    height: 50px;
    border: 1px solid;
    background: var(--color-red);
    border-radius: 25px;
    font-size: 18px;
    color: #e9f4fb;
    font-weight: 700;
    cursor: pointer;
    outline: none;
  }
  
  input[type="submit"]:hover {
    border-color: var(--color-red);
    transition: 0.5s;
  }
  
  .resetpass-signup {
    margin: 30px 0;
    text-align: center;
    font-size: 16px;
    color: #666666;
  }
  
  .resetpass-signup a {
    color: var(--color-red);
    text-decoration: none;
  }
  
  .resetpass-signup a:hover {
    text-decoration: underline;
  }
  
  @media screen and (max-width: 450px) {
    .resetpass-center {
      top: 50%;
      width: 350px;
    }
  
    .resetpass-center h1 {
      font-size: 28px;
      padding: 15px 0 15px 0;
    }
  
    .resetpass-center form {
      padding: 0 20px;
    }
  }
  