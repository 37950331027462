.forgotpassword {
  min-height: 100vh;
  font-family: var(--font-family);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(167, 53, 53, 0.98) 100%
  );
}

.forgotpassword p {
  text-align: center;
  margin-top: 1rem;
  font-weight: 600;
}

#errorMessage {
  color: red;
}

#successMessage {
  color: green;
}

.forgotpassword-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background: white;
  border-radius: 10px;
}

.forgotpassword-center h1 {
  text-align: center;
  padding: 20px 0 20px 0;
  border-bottom: 1px solid silver;
}

.forgotpassword-center form {
  padding: 0 40px;
  box-sizing: border-box;
}

form .forgotpassword-input {
  position: relative;
  border-bottom: 2px solid #adadad;
  margin: 30px 0;
}

.forgotpassword-input input {
  width: 100%;
  padding: 0 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
}

.forgotpassword-input label {
  position: absolute;
  top: 50%;
  left: 5px;
  color: #adadad;
  transform: translateY(-50%);
  font-size: 16px;
  pointer-events: none;
  transition: 0.5s;
}

.forgotpassword-input span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 2px;
  background: var(--color-red);
  transition: 0.5s;
}

.forgotpassword-input input:focus ~ label,
.forgotpassword-input input:valid ~ label {
  top: -5px;
  color: var(--color-red);
}

.forgotpassword-input input:focus ~ span::before,
.forgotpassword-input input:valid ~ span::before {
  width: 100%;
}

input[type="submit"] {
  width: 100%;
  height: 50px;
  border: 1px solid;
  margin-bottom: 30px;
  background: var(--color-red);
  border-radius: 25px;
  font-size: 18px;
  color: #e9f4fb;
  font-weight: 700;
  cursor: pointer;
  outline: none;
}

input[type="submit"]:hover {
  border-color: var(--color-red);
  transition: 0.5s;
}

@media screen and (max-width: 450px) {
  .forgotpassword-center {
    top: 50%;
    width: 350px;
  }

  .forgotpassword-center h1 {
    font-size: 28px;
    padding: 15px 0 15px 0;
  }

  .forgotpassword-center form {
    padding: 0 20px;
  }
}
