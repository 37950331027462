.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(200px, auto);
  grid-gap: 1px;
  justify-items: center;
  align-items: center;
  background-color: rgba(240, 248, 255, 0.863);

  padding: 4rem;
  border-radius: 20px;
}
.insta-content {
  width: 100%;
  height: auto;
  display: block;
  transition: all 100ms ease-out;
}

.container img:hover {
  transform: scale(1.04);
}

@media (max-width: 600px) {
  .slick-slide {
    width: 100%;
  }
  .slick-slider .slick-list {
    margin: 0 -10px;
  }
  .slick-slide .insta-content {
    max-width: 100%;
    margin: 0 auto;
  }
  .slick-slide {
    padding: 0 10px;
  }
  .slick-list {
    margin-left: -10px;
    margin-right: -10px;
  }
  .slick-track {
    margin: 0;
  }
  .slick-arrow {
    display: none !important;
  }
  .slick-dots {
    display: none !important;
  }
}

/* @media (min-width: 400px) and (max-width: 960px) {
  .slick-slide {
    width: 50%;
  }
} */

/* Set slidesToShow to 3 on screens between 960px and 1280px */
/* @media (min-width: 960px) and (max-width: 1600px) {
  .slick-slide {
    width: 33.33%;
  }
} */

/* Set slidesToShow to 5 on screens larger than 1280px */
/* @media (min-width: 1280px) {
  .slick-slide {
    width: 20%;
  }
} */
